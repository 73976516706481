<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT © 2016-{{ new Date().getFullYear() }}
      BIRU Sdn Bhd. All Right Reserved
    </span>
    <span class="float-md-right d-none d-md-block">FARA Dashboard v3 Beta
      <font-awesome-icon icon="heart-broken" size="4x" class="icons" />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
